import { Injectable, isDevMode } from '@angular/core';

import { Theme } from '@enums/theme.enum';

import { UIState } from '@state/state/ui.state';
import { TAG, UIStore } from '@state/stores/ui.store';

import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(
    private storageService: StorageService,
    private readonly uiStore: UIStore
  ) {
    if (isDevMode())
      console.log('[Theme Service] Loading theme:', {
        store: this.uiStore.theme(),
        storage: this.storageService.get<UIState>(TAG)?.theme,
        os: this.osTheme(),
      });
    this.setTheme(this.uiStore.theme());
  }

  osTheme(): Theme {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Theme.Dark
      : Theme.Light;
  }

  currentTheme(): Theme {
    switch (true) {
      case document.body.classList.contains(Theme.Dark):
        return Theme.Dark;
      case document.body.classList.contains(Theme.Light):
        return Theme.Light;
      default:
        return Theme.Light;
    }
  }

  setTheme(theme: Theme): void {
    if (isDevMode()) console.log('[Theme Service] Setting theme:', theme);
    document.body.classList.remove(Theme.Light, Theme.Dark);
    document.body.classList.add(theme);
    this.uiStore.set('theme', theme);
  }

  toggleDarkMode(): Theme {
    if (this.darkMode()) {
      this.setTheme(Theme.Light);
      return Theme.Light;
    } else {
      this.setTheme(Theme.Dark);
      return Theme.Dark;
    }
  }

  darkMode(): boolean {
    return this.currentTheme() === Theme.Dark;
  }
}
